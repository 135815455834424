import React from "react";
import { SiMaildotru } from "react-icons/si";
import { AiFillPhone } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";

function Loja(props) {
  return (
    <div className="loja">
      <div className="lojaContainer">
        <div>
          <img
            className="imagensLogo"
            src={props.loja.img}
            alt="Logotipo {props.loja.nome}"
          />
        </div>
        <div className="lojaDescricao">
          <h2 className="nomeLoja">{props.loja.nome}</h2>
          {props.loja.endereco.map((end) => {
            if (props.loja.endereco.length == 1) {
              return (
                <p className="linkLojaEndereco">
                  <IoLocationSharp className="icone" />{" "}
                  {end.endereco.toUpperCase()}
                </p>
              );
            } else {
              return (
                <p className="linkLojaEndereco">                   
                  <IoLocationSharp className="icone" /> Loja {end.loja} :{" "}
                  {end.endereco.toUpperCase()}
                  <br />
                </p>
              );
            }
          })}

          <a
            className="linkLoja"
            href={"tel:" + parseInt(props.loja.telefone.replace(/[^0-9]/g, ""))}
          >
             <p className="linkLojaEmail">
                
            <AiFillPhone className="icone" /> {props.loja.telefone}
             </p>
          </a>
          <a className="linkLoja" href={"mailto:" + props.loja.email}>
          <p className="linkLojaEmail">
              <SiMaildotru className="icone" /> 
              {props.loja.email}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Loja;
