import React from "react";
import { Link } from "react-router-dom";
import Logo from "./../assets/MinasSuperLogoVert.png";
import { GiHamburgerMenu } from 'react-icons/gi';

export default function Navbar(props) {
    return (
        <>
      {console.log(props.exibe)}
      <div className="navbarMain">
        <div className="boxNavbarMain">
          <div className="navbarEndereco">
            <Link to="/">
              <img src={Logo} className="navbarLogo"></img>
            </Link>
        {window.screen.width < 1012 && (
            <div class="dropdown">
            <button
              class="noContent"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <GiHamburgerMenu className="iconeMenu"/>  
            </button>
            <div class="dropdown-menu dropdown-menu-right dropdownNavBar" aria-labelledby="dropdownMenu2">
              <>
              <div>

                <Link to="/lojas">
                  <button class="btn btn-danger btnDropDown">NOSSAS LOJAS</button>
                </Link>
              </div>
              <div>

                <Link to="/sobre">
                <button class="btn btn-danger btnDropDown">SOBRE NÓS</button>
              </Link>
              </div>
              <div>
              <a
                href="https://minassuper.com.br/retweb/retweb_teste/"
                >
                <button class="btn btn-danger btnDropDown">AREA ASSOCIADOS</button>
              </a>
              </div>
              </>
            </div>
          </div>
        )}
          </div>
          <div className="navbarEndereco">
            <div
              className="d-flex justify-content-end"
              style={{ marginRight: "-5rem" }}
              >
              <a
                href="https://minassuper.com.br/retweb/retweb_teste/"
                className="areaAssociados"
                >
                AREA ASSOCIADOS
                {/* <button type="button" class="btn btn-danger btnHeader">ACESSO ASSOCIADOS</button> */}
              </a>
            </div>
            <div className="navbarBtns">
              <>
                <Link to="/lojas">
                  <button class="btn btn-danger btnHeader ">NOSSAS LOJAS</button>
                </Link>
              </>
              <Link to="/sobre">
                <button class="btn btn-danger btnHeader">SOBRE NÓS</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
