import React from "react";
import Loja from "./Loja";
import LacerdaImg from "./../assets/lojas/ALFENAS.png";
import CasaVerdeImg from "./../assets/lojas/ANDRADAS.png";
import BetteImg from "./../assets/lojas/Bette.png";
import DmImg from "./../assets/lojas/Campanha.png";
import UniaoImg from "./../assets/lojas/Uniao.png";
// import CaldasImg from "./../assets/lojas/Caldas"
import JFMImg from "./../assets/lojas/SupVanda.png";
import RuiImg from "./../assets/lojas/COQQUEIRAL.png";
import CorregoDoOuroImg from "./../assets/lojas/MC.png";
import VencedoraImg from "./../assets/lojas/Vencedora.png";
import ItamonteImg from "./../assets/lojas/Itamonte.png";
// import MafraImg from "./../assets/lojas/"
// import IrmaosTorresImg from "./../assets/lojas/C"
import DouradinhoImg from "./../assets/lojas/Douradinho.png";
import VilaRicaImg from "./../assets/lojas/VilaRica.png";
import AvenidaImg from "./../assets/lojas/Avenida.png";
import CaracolImg from "./../assets/lojas/Caracol.png";
import AmaralImg from "./../assets/lojas/Amaral.png";
import FerreiraImg from "./../assets/lojas/SF.png";
import VitoriaImg from "./../assets/lojas/Vitoria.png";
import GomesImg from "./../assets/lojas/Gomes.png";
import GenericImg from "./../assets/lojas/Generic.png";
import ListaLojas from "./ListaLojas";

import CarlimImg from "./../assets/lojas/CarlimSupermercados.png";
import AsaBrancaImg from "./../assets/lojas/AsaBrancaSupermercados.png";
import IdealImg from "./../assets/lojas/idealSupermercados.png";
import MajorImg from "./../assets/lojas/MajorSuperMercados.png";
import SuperMarteImg from "./../assets/lojas/SuperMarte.png";
import SmartSouzaImg from "./../assets/lojas/supermercadoSouza.png";
import CapitaoImg from "./../assets/lojas/VarejaoCapitao.png";
import { useState } from "react";

export default function Lojas() {
  let centralLojas = [
    {
      id: "1",
      nome: "Supermercados Casa Verde",
      endereco: [
        {
          loja: 1,
          endereco:
            "AV. MÁRIO LANZANI,345 BAIRRO JARDIM AMERICA , Andradas - MG",
        },
        {
          loja: 2,
          endereco:
            "AV. MÁRIO LANZANI,345 BAIRRO JARDIM AMERICA , Andradas - MG",
        },
      ],
      telefone: "(35) 3291-5097",
      email: "casaverdesupermercadosmatriz@hotmail.com",
      img: CasaVerdeImg,
    },
    {
      id: "2",
      nome: "Supermercados Lacerda",
      endereco: [
        {
          loja: 1,
          endereco:
            "AVENIDA PEDRO ALBERTO LEITE, 840 BAIRRO VILA SANTA EDWIRGES, Alfenas - MG",
        },
        {
          loja: 2,
          endereco:
            "BENJAMIM CONSTANT, 3153 BAIRRO JARDIM SÃO CARLOS, Alfenas - MG",
        },
        {
          loja: 3,
          endereco:
            "RUA UMBELINDA DA SILVEIRA PINTO, 334 BAIRRO JARDIM AMERICA I, Alfenas - MG",
        },
      ],
      telefone: "(35) 3291-5097",
      email: "ricardolacerda@grupolacerda.com.br",
      img: LacerdaImg,
    },
    {
      id: "3",
      nome: "Supermercado Bette",
      endereco: [
        {
          loja: 1,
          endereco: "CEL VICENTE DE SEIXAS ,53 CENTRO, Baependi - MG",
        },
      ],
      telefone: "(35) 9 9990-1146",
      email: "nfeletronicabette@gmail.com",
      img: BetteImg,
    },
    {
      id: "4",
      nome: "Supermercado DM",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA COMENDADOR NILTON VAL RIBEIRO ,798 BAIRRO CENTRO, Campanha - MG",
        },
      ],
      telefone: "(35) 9 9961-2822",
      email: "dmsupermercado@hotmail.com",
      img: DmImg,
    },
    {
      id: "5",
      nome: "Supermercados União",
      endereco: [
        {
          loja: 1,
          endereco: "PRAÇA DELFIM MOREIRA, 92 BAIRRO CENTRO, Campestre - MG",
        },
        {
          loja: 2,
          endereco:
            "RUA CEL. JOSE GUILHERME, 101 BAIRRO CENTRO, Campestre - MG",
        },
        {
          loja: 3,
          endereco:
            "RUA VITOR AVELINO DE CARVALHO, 51 BAIRRO VITOR MAURO, Campestre - MG",
        },
      ],
      telefone: "(35) 9 3743-2107",
      email: "uniao0003@yahoo.com.br",
      img: UniaoImg,
    },
    // {
    //   id: "6",
    //   nome: "Supermercados Pinduka",
    //   endereco: [
    //     {
    //       loja: 1,
    //       endereco: "PÇ DR. PAULINO FIGUEIREDO, 181 CENTRO, Caldas - MG",
    //     },
    //   ],
    //   telefone: "(35) 9 9176-7774",
    //   email: "nfe.pinduka@gmail.com",
    //   img: GenericImg,
    // },
    {
      id: "7",
      nome: "Supermercados JFM",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA CARMELITA CARVALHO GARCIA ,63 BAIRRO CENTRO, Cana Verde - MG",
        },
      ],
      telefone: "(35) 3865-1206",
      email: "Supvanda@gmail.com",
      img: JFMImg,
    },
    {
      id: "8",
      nome: "Supermercados Rui",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA CORONEL JOÃO BORGES, 128 BAIRRO CENTRO, Coqueiral - MG",
        },
      ],
      telefone: "(35) 9 8896-2018",
      email: "ruisupermercado@yahoo.com.br",
      img: RuiImg,
    },
    {
      id: "9",
      nome: "Supermercados Corrego do Ouro",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA ANTONIO PEREIRA ALVIM, 263 BAIRRO CORREGO DO OURO, Campos Gerais - MG",
        },
      ],
      telefone: "(35) 3853-5004",
      email: "sucol2009@hotmail.com",
      img: CorregoDoOuroImg,
    },
    {
      id: "10",
      nome: "Supermercados Vencedora",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA JOSE ALVARES MACIEL, 134 BAIRRO CENTRO, Heliodora - MG",
        },
      ],
      telefone: "(35) 3457-1274",
      email: "smartvencedora.heliodora@hotmail.com",
      img: VencedoraImg,
    },
    {
      id: "11",
      nome: "Supermercados Matheus Thieres",
      endereco: [
        {
          loja: 1,
          endereco:
            "AV. JOSÉ VILELA DA COSTA, 485 BAIRRO SANTA MARIA, Ilicínia - MG",
        },
      ],
      telefone: "(35) 3854-1344",
      email: "carlosmatheusthieres@outlook.com",
      img: GenericImg,
    },
    {
      id: "12",
      nome: "Supermercados Itamonte",
      endereco: [
        {
          loja: 1,
          endereco:
            "AV. JOSÉ VILELA DA COSTA, 485 BAIRRO SANTA MARIA, Itamonte - MG",
        },
        {
          loja: 2,
          endereco:
            "RUA MONSENHOR JOSE AUGUSTO ALKIMIN, 42 CENTRO, Conceição do Rio Verde - MG",
        },
      ],
      telefone: "(35) 3335-3156",
      email: "ovpneto@yahoo.com.br",
      img: ItamonteImg,
    },
    {
      id: "13",
      nome: "Supermercados Mafra",
      endereco: [
        {
          loja: 1,
          endereco: "AV. FERNANDO COSTA, 560 CENTRO, Itanhandu  - MG",
        },
      ],
      telefone: "(35) 9 8862-4260",
      email: "supermercadomafra@gmail.com",
      img: GenericImg,
    },
    {
      id: "14",
      nome: "Supermercados Irmãos Torres",
      endereco: [
        {
          loja: 1,
          endereco:
            "PREFEITO DILERMANDO OLIVEIRA, 511 CENTRO, Conceição do Rio Verde - MG",
        },
      ],
      telefone: "(35) 3335-1027",
      email: "venilton.torres@hotmail.com",
      img: GenericImg,
    },
    {
      id: "15",
      nome: "Supermercados Douradinho",
      endereco: [
        {
          loja: 1,
          endereco:
            "PRACA BELO HORIZONTE, 77 DISTRITO DOURADINHO, Machado - MG",
        },
      ],
      telefone: "(35) 9 9927-6781",
      email: "e.tavares.leal@bol.com.br",
      img: DouradinhoImg,
    },
    {
      id: "16",
      nome: "Supermercados Vila Rica",
      endereco: [
        {
          loja: 1,
          endereco:
            "PRAÇA JOAO PEDRO BONELI,128 BAIRRO CENTRO, Monte Belo - MG",
        },
        {
          loja: "Tres Irmas",
          endereco:
            "Rua CEL JOAO EVANGELISTA DOS ANJOS, Nº 800 , Monte Belo - MG",
        },
      ],
      telefone: "(35) 9 9135-4433",
      email: "vilarica02@hotmail.com",
      img: VilaRicaImg,
    },
    {
      id: "17",
      nome: "Supermercados Avenida",
      endereco: [
        {
          loja: 1,
          endereco: "AV DOM BOSCO, 424 BAIRRO CENTRO, Paraguaçu - MG",
        },
      ],
      telefone: "(35) 3267-3305",
      email: "moterani.adriano@hotmail.com",
      img: AvenidaImg,
    },
    {
      id: "18",
      nome: "Supermercados Caracol",
      endereco: [
        {
          loja: 1,
          endereco:
            "PRACA DR PAULO DE FRONTIN, 17 BAIRRO CENTRO, Passa Quatro - MG",
        },
      ],
      telefone: "(35) 9 8857-4595",
      email: "jmsiqueira17@hotmail.com",
      img: CaracolImg,
    },
    {
      id: "19",
      nome: "Supermercados Amaral",
      endereco: [
        {
          loja: 1,
          endereco: "RUA BERNARDINA RUTTEN,22 , Perdões - MG",
        },
      ],
      telefone: "(35) 9 9761-3812",
      email: "supermercadoamaral@hotmail.com",
      img: AmaralImg,
    },
    {
      id: "20",
      nome: "Supermercados Ferreira",
      endereco: [
        {
          loja: 1,
          endereco: "RUA CAPITAO ANTONIO GONÇALVES,463, Poço Fundo - MG",
        },
      ],
      telefone: "(35) 9 9190-0203",
      email: "supermercadoferreiraadm@hotmail.com",
      img: FerreiraImg,
    },
    // {
    //   id: "21",
    //   nome: "Supermercados Vitoria",
    //   endereco: [
    //     {
    //       loja: 1,
    //       endereco: "RUA CAPITAO ANTONIO GONÇALVES,463, Poços de Caldas - MG",
    //     },
    //   ],
    //   telefone: "(35) 3714-3936",
    //   email: "supermercadovitoriapc@hotmail.com",
    //   img: VitoriaImg,
    // },
    {
      id: "22",
      nome: "Supermercados Gomes",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA BARBARA HELIODORA, NR. 301 BAIRRO CARIOCA, São Lourenço - MG",
        },
        // {
        //   loja: 2,
        //   endereco: "RUA QUINZE DE NOVEMBRo, 32 CENTRO, São Lourenço - MG",
        // },
        // {
        //   loja: 3,
        //   endereco: "RUA CEL JOSÉ JUSTINO,334 BAIRRO CENTRO, São Lourenço - MG",
        // },
      ],
      telefone: "(35)3339-3401",
      email: "luizantoniogomes@empresasgomes.com.br",
      img: GomesImg,
    },
  ];


  let norteLojas = [
    {
      id: "1",
      nome: "Supermercados Major",
      endereco: [
        {
          loja: 1,
          endereco:
            "AV. BIAS FORTES,87 CENTRO, BRASILIA DE MINAS - MG",
        },
        {
          loja: 2,
          endereco:
            "RUA CORACAO DE JESUS,1435 BAIRRO ALTO CLARO, BRASILIA DE MINAS - MG",
        },
        {
            loja: 3,
            endereco:
              "AV BRASIL, 363 BAIRRO JACARÉ, BRASILIA DE MINAS - MG",
          },
          {
            loja: 4,
            endereco:
              "RUA BELA VISTA, 1245 BAIRRO ALTEROSAS, MONTES CLAROS - MG",
          },
      ],
      telefone: "(38) 99952-2927",
      email: "faturamento@majorsupermercados.com.br",
      img: MajorImg,
    },
    {
      id: "2",
      nome: "Supermercados Varejão Capitão",
      endereco: [
        {
          loja: 1,
          endereco:
            "RUA NSRA DA GUIA, 112 BAIRRO CENTRO, CAPITÃO ENEAS - MG",
        },
        {
          loja: 2,
          endereco:
            "AV PEDRO MINEIRO, 1517 BAIRRO SAPÉ, CAPITÃO ENEAS - MG",
        },
      ],
      telefone: "(38) 99997-8142",
      email: "compras@varejaocapitao.com.br",
      img: CapitaoImg,
    },
    {
        id: "3",
        nome: "Supermercados Super Smart Azevedo",
        endereco: [
          {
            loja: 1,
            endereco:
              "RUA JANUÁRIA, 528 BAIRRO CENTRO, ITACARAMBI - MG",
          },
          {
            loja: 2,
            endereco:
              "RUA JANUÁRIA, 244 BAIRRO CENTRO, ITACARAMBI - MG",
          },
        ],
        telefone: "(38) 99843-3336",
        email: "smartazevedo1@hotmail.com",
        img: GenericImg,
      },
      {
        id: "4",
        nome: "Supermercados Pag Pouco",
        endereco: [
          {
            loja: 1,
            endereco:
              "AVENIDA CÔNEGO RAMIRO LEITE, 132 BAIRRO CENTRO, JANUÁRIA - MG",
          },
        ],
        telefone: "(38) 99199-0606",
        email: "elvecinhopagpouco@yahoo.com.br",
        img: GenericImg,
      },
      {
        id: "5",
        nome: "Supermercados Asa Branca",
        endereco: [
          {
            loja: 1,
            endereco:
              "AVENIDA BRASIL, 465 BAIRRO CENTRO, JANUBA - MG",
          },
          {
            loja: 2,
            endereco:
              "AVENIDA MANOEL ATAÍDE,2548 BAIRRO SANTA CRUZ, JANUBA - MG",
          },
        ],
        telefone: "(38) 3821-1077",
        email: "compras@supermercadoasabranca.com.br",
        img: AsaBrancaImg,
      },
      {
        id: "6",
        nome: "Supermercados Fort",
        endereco: [
          {
            loja: 1,
            endereco:
              "AVENIDA SAUDADE,465 BAIRRO CENTRO, JANUBA - MG",
          },
        ],
        telefone: "(38) 9983-0360",
        email: "ney.araujo@yahoo.com.br",
        img: GenericImg,
      },
      {
        id: "7",
        nome: "Supermercados Na Hora",
        endereco: [
          {
            loja: 1,
            endereco:
              "RUA OLÍMPIO CAMPOS, 119 BAIRRO CENTRO, LONTRA - MG",
          },
        ],
        telefone: "(38) 99820-013",
        email: "rosy.nahora@yahoo.com.br",
        img: GenericImg,
      },
            {
        id: "8",
        nome: "Supermercados Carlim",
        endereco: [
          {
            loja: 1,
            endereco:
              "AVENIDA CASTELAR PRATES , 197 BAIRRO MAJOR PRATES, MONTES CLAROS - MG",
          },
          {
            loja: 2,
            endereco:
              "AVENIDA PADRE CHICO, 362 BAIRRO MARACANÃ, MONTES CLAROS - MG",
          },
          {
            loja: 3,
            endereco:
              "PRAÇA BEATO FRANCISCO COLL, 54 BAIRRO MARACANÃ, MONTES CLAROS - MG",
          },
          {
            loja: 4,
            endereco:
              "RUA PADRE VIEIRA, 103 BAIRRO SÃO JUDAS TADEU, MONTES CLAROS - MG",
          },
          {
            loja: 5,
            endereco:
              "RUA GERALDINO MACHADO, 450 BAIRRO SANTOS REIS, MONTES CLAROS - MG",
          },
          {
            loja: 6,
            endereco:
              "AVENIDA RUI ALBUQUERQUE, 2800 BAIRRO NOVO JARAGUA, MONTES CLAROS - MG",
          },
        ],
        telefone: "(38) 99953-3695",
        email: "compras@carlimsupermercado.com.br",
        img: CarlimImg,
      },
      {
        id: "9",
        nome: "Supermercados Varejão Ideal",
        endereco: [
          {
            loja: 1,
            endereco:
              "RUA ROQUE FERREIRA PIMENTA, 235 BAIRRO DELF MAGALHÃES, MONTES CLAROS - MG",
          },
          {
            loja: 2,
            endereco:
              "RUA I, 05 BAIRRO VILA ANÁLIA, MONTES CLAROS - MG",
          },
          {
            loja: 3,
            endereco:
              "AVENIDA PAULISTA, 611 BAIRRO SANTO ANTONIO, MONTES CLAROS - MG",
          },
        ],
        telefone: "(38) 99954-1266",
        email: "varejaoidealsmart@hotmail.com",
        img: IdealImg,
      },
      {
        id: "10",
        nome: "Supermercados Super Marte",
        endereco: [
          {
            loja: 1,
            endereco:
              "AVENIDA MONTES CLAROS, 561 BAIRRO CENTRO, SÃO FRANCISCO - MG",
          },
        ],
        telefone: "(38) 99911-7152",
        email: "supermercadomarte@yahoo.com.br",
        img: SuperMarteImg,
      },
      {
        id: "11",
        nome: "Supermercados Varejão Bla Bla Bla",
        endereco: [
          {
            loja: 1,
            endereco:
              "RUA ALTAIR CORDEIRO,1360 BAIRRO SAGRADA FAMILÍA, SÃO FRANCISCO - MG",
          },
        ],
        telefone: "(38) 99902-2329",
        email: "supervareejaoblablabla@hotmail.com",
        img: GenericImg,
      },
      {
        id: "12",
        nome: "Supermercados Alves",
        endereco: [
          {
            loja: 1,
            endereco:
              "RUA ELIZEU VELOZO, 151 BAIRRO CENTRO, UBAÍ - MG",
          },
        ],
        telefone: "(38) 99805-9804",
        email: "lauroafonso1@hotmail.com",
        img: GenericImg,
      },
      {
        id: "13",
        nome: "Supermercados Smart Souza",
        endereco: [
          {
            loja: 1,
            endereco:
              "RUA 31 DE DEZEMBRO,168 BAIRRO CENTRO, SÃO JOÃO DA PONTE - MG",
          },
          {
            loja: 2,
            endereco:
              "RUA HUMBERTO GOMES,121 BAIRRO CENTRO, SÃO JOÃO DA PONTE - MG",
          },
        ],
        telefone: "(38)99191-4441",
        email: "supermercadosouza@gmail.com",
        img: SmartSouzaImg,
      },
  ];


 const [central, setCentral] = useState('MinasSuperCentral');
 const [lojaDetalhes,  setLojaDetalhes] = useState(centralLojas);
  function minasSuperNorte(){
    setCentral('MinasSuperNorte');
    setLojaDetalhes(norteLojas);
}
function minasSuperCentral(){
    setCentral('MinasSuperCentral');
    setLojaDetalhes(centralLojas);
  }
  return (
    <div className="lojasContainer">
      <div className="cabecalho">
        <div className="tituloLojas">
          <h1>Rede Minas Super</h1>
        </div>
        <p className="textoLojas">
          A Rede Minas Super é uma importante rede de supermercadistas presente
          em diversas regiões mineiras que tem como objetivo entregar agilidade,
          qualidade e bons preços para os clientes e conveniados.
        </p>

        <ul class="nav nav-tabs navs">
  <li class='nav-item'>
    <a class={central == 'MinasSuperCentral' 
    ? 'btn btn-danger btnHeader_selected buttonRegiao' 
    : 'btn btn-danger btnHeader_unSelected buttonRegiao'} 
    href="#" onClick={()=>minasSuperCentral()} >Região Sul</a>
  </li>
  <li class='nav-item'>
    <a class={central == 'MinasSuperNorte' 
    ? 'btn btn-danger btnHeader_selected buttonRegiao ' 
    : 'btn btn-danger btnHeader_unSelected buttonRegiao'} 
    href="#"  onClick={() =>minasSuperNorte()} >Região Norte</a>
  </li>

</ul>
<ListaLojas
lojaDetalhes = {lojaDetalhes}
/>

      </div>
      {/* {lojaDetalhes.map((loja) => {
        return (
          <>
            <Loja key={loja.id} className="mb-5" loja={loja} />
          </>
        );
      })} */}
    </div>
  );
}
