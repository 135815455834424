import React from "react";
import { Routes, Route } from "react-router-dom";
import Inicial from "./components/Inicial";
import Lojas from "./components/Lojas";
import Navbar from "./components/Navbar";
import SobreNos from "./components/SobreNos";

export default () => {
  return (
    <>
      <Navbar />
      <Routes>
      <Route exact path="/" element={<Inicial />} />
        <Route exact path="/site" element={<Inicial />} />
        <Route exact path="/lojas" element={<Lojas />} />
        <Route exact path="/sobre" element={<SobreNos />} />
      </Routes>
    </>
  );
};
