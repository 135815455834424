import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Lojas from "./components/Lojas";
import SobreNos from "./components/SobreNos";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
