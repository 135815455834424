import React from "react";
import Azeites from "./../assets/capa/azeites1.jpg";
import Vinhos from "./../assets/capa/vinhos1.jpg";
import Casal from "./../assets/capa/casal1.jpg";
import Carrinho from "./../assets/capa/carrinho1.webp";
import Logo from "./../assets/MinasSuperLogo.png";
import Familia from "./../assets/capa/familia.jpg";
import Azeitonas from "./../assets/capa/azeitonas.jpg";
import Frutas from "./../assets/capa/frutas.jpg";
import Legumes from "./../assets/capa/legumes.jpg";

import Slider from "react-slick";

export default function Inicial() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000 * 4,
    arrows: true,
    vertical: true,
    fade: true,
    cssEase: "linear",
    // centerMode: true
  };

  return (
    <div className="inicialIni">
      <div className="inicialCarrousel">
        <Slider {...settings}>
        <div>
            <img
              src={Familia}
              className="ImgCapa"
              alt="imagem decorativa do supermercado (Imagem de Victoria_rt por Pixabay )"
            />
          </div>
          <div>
            <img
              src={Frutas}
              className="ImgCapa"
              alt="imagem decorativa do supermercado (Imagem de Igor Ovsyannykov por Pixabay)"
            />
          </div>
          <div>
            <img
              src={Azeitonas}
              className="ImgCapa"
              alt="imagem decorativa do supermercado (Imagem de Orna Wachman por Pixabay)"
            />
          </div>
          <div>
            <img
              src={Legumes}
              className="ImgCapa"
              alt="imagem decorativa do supermercado (Imagem de photosforyou por Pixabay)"
            />
          </div>
        </Slider>
      </div>
      <div className="inicialLateral">
        <img src={Logo} className="inicialLogo" />
        <h2 className="inicialTexto">Encontre os melhores produtos aqui!</h2>
      </div>
    </div>
  );
}
