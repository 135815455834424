import React from "react";
import Loja from "./Loja";

export default function ListaLojas(props){

    return(
        <>

        {props.lojaDetalhes.map((loja) => {
            return (
                <>
                <Loja key={loja.id} className="mb-5" loja={loja} />
                </>
                );
        })}

        </>
    )
}