import React from "react";
import Contato from "./Contato";
import Estrategia from "./Estrategia";
import Historia from "./Historia";
import Objetivo from "./Objetivo";


export default function SobreNos(){
    return(
        <>
        <h1>Em construção</h1>
{/* <Historia/>
<Estrategia/>
<Objetivo/>
<Contato/> */}
        </>
    )
}

